import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from '../styles/theme'
import { getLink } from '../hooks/url-state'
import { Chart } from '../domain/chart'
import { EmailShareButton, FacebookShareButton, VKShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, OKShareButton, MailruShareButton } from 'react-share'
import { Clipboard, Copy, Mail, Share2, AtSign, ExternalLink } from 'react-feather'
import { Vk, Facebook, Telegram, Twitter, Whatsapp, Odnoklassniki } from 'styled-icons/simple-icons'
import { IconButton } from './IconButton'
import InputGroup from 'react-bootstrap/InputGroup'

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

interface IProps {
  chart: Chart
}

export const ShareButtons = ({ chart }: IProps) => {
  const [link, setLink] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    setLink(null)
  }, [JSON.stringify(chart)])

  function share() {
    const url = getLink(chart, '/chord')

    setLink(url)

    return new Promise((resolve) => {
      setTimeout(resolve, 300)
    })
  }

  const title = `Генератор аккордовых диаграмм SAMESOUND | ${chart.settings.title || 'Аккорд без названия'}`

  const copyLink = () => {
    if (inputRef.current) {
      inputRef.current.select()
      document.execCommand('copy')
    }
  }

  return (
    <>
      <h2 id="share">Поделиться</h2>
      <IconButton variant="outline-dark" onClick={share}>
        <ExternalLink width={18} height={18} />
		Ссылка на диаграмму
      </IconButton>

      {link && (
        <>
          <InputGroup className="my-3">
            <input className="form-control" ref={inputRef} aria-label="Ссылка на диаграмму" type="text" value={link} readOnly={true} />
            <InputGroup.Append>
              <IconButton variant="outline-dark" onClick={copyLink}>
                <Copy width={20} height={20} />
                
              </IconButton>
            </InputGroup.Append>
          </InputGroup>

          <ButtonContainer>
			<VKShareButton url={link}>
              <IconButton size="sm" variant="outline-dark">
                <Vk width={20} height={20} />
				VK
				</IconButton>
            </VKShareButton>

            <TelegramShareButton url={link} title={title}>
              <IconButton size="sm" variant="outline-dark">
                <Telegram width={20} height={20} />
                Телеграм
              </IconButton>
            </TelegramShareButton>
			
			<FacebookShareButton url={link}>
              <IconButton size="sm" variant="outline-dark">
                <Facebook width={20} height={20} />
                Фейсбук
              </IconButton>
            </FacebookShareButton>

            <TwitterShareButton url={link} title={title} via="https://guitardiagrams.samesound.ru" hashtags={['гитара', 'аккорд', 'guitar', 'chord']}>
              <IconButton size="sm" variant="outline-dark">
                <Twitter width={20} height={20} />
                Твиттер
              </IconButton>
            </TwitterShareButton>
			
			<OKShareButton url={link}>
              <IconButton size="sm" variant="outline-dark">
                <Odnoklassniki width={20} height={20} />
				ОК
				</IconButton>
            </OKShareButton>
			
			<MailruShareButton url={link}>
              <IconButton size="sm" variant="outline-dark">
                <AtSign width={20} height={20} />
				Mail.ru
				</IconButton>
            </MailruShareButton>

            <WhatsappShareButton url={link} title={title}>
              <IconButton size="sm" variant="outline-dark">
                <Whatsapp width={20} height={20} />
                WhatsApp
              </IconButton>
            </WhatsappShareButton>

            <EmailShareButton subject={title} url={link} body="Диаграмма аккорда, которую я сделал в Генераторе диаграмм SAMESOUND">
              <IconButton size="sm" variant="outline-dark">
                <Mail width={20} height={20} />
                Email
              </IconButton>
            </EmailShareButton>
          </ButtonContainer>
        </>
      )}
    </>
  )
}

export default ShareButtons
